import React, { useState } from "react";
import styled from "styled-components";

// Styled checkbox container
const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
`;

// Styled label for better accessibility and design
const Label = styled.label`
  margin-left: 15px;
  font-size: 14px;
`;

// Styled input for the checkbox
const Checkbox = styled.input`
  width: 20px;
  height: 20px;
  cursor: pointer;
  text-align: left;
`;

export const PhotoConsentCheckbox = ({ formData, setFormData, language }) => {
  const [isChecked, setIsChecked] = useState(false);

  const toggleCheckbox = (event) => {
    setIsChecked(!isChecked);
    const { name, checked } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: checked,
    }));
  };

  console.log(formData);

  return (
    <>
      {language === "en" ? (
        <CheckboxContainer>
          <Checkbox
            type="checkbox"
            id="photoConsent"
            checked={isChecked}
            name="photoConsent"
            onChange={toggleCheckbox}
          />
          <Label htmlFor="photoConsent">
            Consent For Photography: Participants of the MTR Mall Synthetic Ice
            Rink Pop Up Tour agree to authorize the organizing parties to use
            all videos and photos taken at the Tour for marketing purposes.
          </Label>
        </CheckboxContainer>
      ) : (
        <CheckboxContainer>
          <Checkbox
            type="checkbox"
            id="photoConsent"
            checked={isChecked}
            name="photoConsent"
            onChange={toggleCheckbox}
          />
          <Label htmlFor="photoConsent">
            使用照片同意聲明:所有社區冰球計劃的參加者同意授權主辦及協辦機構使用所有活動照片及影片作商業宣傳用途。
          </Label>
        </CheckboxContainer>
      )}
      ;
    </>
  );
};
