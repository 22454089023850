import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import api from '../../Api'; // adjust the path based on where api.js is located



export const classData = [
  {
    title: "EmpireOrange",
    venue: "Mega Ice",
    image: "/classImg1.png",
    color: "orange",
  },
  {
    title: "EmpireRed",
    venue: "Class Venue",
    image: "/classImg2.png",
    color: "red",
  },
  {
    title: "EmpireBlack",
    venue: "Class Venue",
    image: "/classImg3.png",
    color: "black",
  },
  {
    title: "EmpireGold",
    venue: "Class Venue",
    image: "/classImg4.png",
    color: "rgba(205,162,9,1)",
  },
  {
    title: "CampU7-U11",
    venue: "Class Venue",
    image: "/classImg5.png",
    color: "rgba(0, 89, 142, 0.8)",
  },
  {
    title: "CampU12-U15",
    venue: "Class Venue",
    image: "/classImg6.png",
    color: "rgba(0, 89, 142, 0.8)",
  },
  {
    title: "Lohas Class",
    venue: "Class Venue",
    image: "/classImg7.png",
    color: "rgba(0, 174, 152, 0.8)",
  },
  {
    title: "Private Lesson with Coach Ben",
    venue: "Class Venue",
    image: "/classImg8.png",
    color: "blue",
  },
  {
    title: "Private Group Lesson with Coach Ben",
    venue: "Class Venue",
    image: "/classImg8.png",
    color: "blue",
  },
  {
    title: "Private Lesson with Coach Whitney",
    venue: "Class Venue",
    image: "/classImg9.png",
    color: "rgba(18, 95, 22, 0.8)",
  },
  {
    title: "Private Group Lesson with Coach Whitney",
    venue: "Class Venue",
    image: "/classImg9.png",
    color: "rgba(18, 95, 22, 0.8)",
  },
];





const ClassCard = ({ id, code, name, venue, images, color }) => (
  <>
		<Link to={`program/${code}`}>
    <CardWrapper>
      <ClassImage src={images} alt={`${name}`} loading="lazy" />
      <ClassInfo>
        <ClassTitle style={{ color }}>{name}</ClassTitle>
        <ClassVenue>{venue}</ClassVenue>
      </ClassInfo>
    </CardWrapper>
   </Link>
  </>
);

const Class = () => {

const [data, setData] = useState([]);

const host = window.location.hostname;
const subdomain = host.split('.')[0];
const shopName = subdomain;



const location = useLocation();


  useEffect(() => {
		const fetchData = async () => {
			try {
				const res = await api.get(
					`/classBooking/api/class/`
				);

				// let uniqueArray = [...new Set(res.data.map((item) => item.category))]; // Remove duplicates

				// let newtabValues = [
				// 	{ name: "tab_1", label: "All Events" },
				// 	...uniqueArray.map((item, index) => ({
				// 		name: `tab_${index + 2}`, // Start from tab_2
				// 		label: item,
				// 	})),
				// ];
				setData(res.data);
			} catch (err) {
				console.log(err);
			}
		};

		fetchData();
		// setResultChange("All");

		// const intervalId = setInterval(fetchData, 1000);

		// Clean up the interval when the component unmounts or when dependencies change
		// return () => {
		// 	clearInterval(intervalId);
		// };




	}, []);


  return (
  <CenteredWrapper>
    <GridContainer>
      {data?.map((classItem, index) => (
        <ClassCard key={index} {...classItem} />
      ))}
    </GridContainer>
  </CenteredWrapper>
)
};

export default Class;

//STYLED COMPONENTS:
export const CenteredWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
`;

export const GridContainer = styled.section`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  max-width: 500px;
  width: 100%;
  text-align: center;
  line-height: 18px;
  gap: 20px;
  padding-top: 20px;

  @media (max-width: 300px) {
    grid-template-columns: 1fr;
  }
`;

export const CardWrapper = styled.article`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 10px;
  width: 100%;
  box-sizing: border-box;
`;

export const ClassImage = styled.img`
  aspect-ratio: 0.7;
  object-fit: cover;
  object-position: center;
  width: 100%;
  max-width: 150px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  border-radius: 8%;
  border: 1px solid gray;
`;

export const ClassInfo = styled.div`
  max-width: 150px;
  margin-top: 5px;
  width: 100%;
`;

export const ClassTitle = styled.h3`
  font-family: var(--font-poppins);
  font-weight: 700;
  font-size: 14px;
  margin: 0;
`;

export const ClassVenue = styled.p`
  font-family: var(--font-lora);
  font-weight: 600;
  font-size: 14px;
  margin: 0;
`;
