import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Cookies from "js-cookie";

// Styled components
const Container = styled.div`
  padding: 20px;
  background-color: #f4f4f4;
  font-size: 14px;
  width: 100%;
`;

const Dropdown = styled.select`
  width: 100%;
  padding: 2vw;
  margin-bottom: 2vw;
  border: 1px solid #ccc;
  border-radius: 5px;
  -webkit-appearance: none; /* Removes default browser styling */
  background-image: url('data:image/svg+xml;utf8,<svg fill="black" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>'); /* Adds custom dropdown arrow */
  background-repeat: no-repeat;
  background-position: right 1rem center; /* Positions the custom dropdown arrow */
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(70px, 1fr));
  gap: 5px;
`;

const TimeslotButton = styled.button`
  position: relative;
  padding: 10px;
  border: ${(props) =>
    props.selected
      ? "3px solid rgba(255, 133, 89, 1)"
      : "2px solid rgba(0, 0, 0, 0.1)"};
  background-color: ${(props) =>
    props.selected ? "rgba(255, 133, 89, 0.3)" : "#ffffff"};
  color: rgba(0, 0, 0, 1);
  border-radius: 5px;
  cursor: ${(props) => (props.available ? "pointer" : "default")};
  &:hover {
    background-color: ${(props) =>
      props.selected ? "rgba(255, 133, 89, 0.3)" : "#ffffff"};
  }

  &.full {
    background-color: lightgrey;
  }

  &.full::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top right, transparent 50%, grey 50%);
    opacity: 0.5;
    box-sizing: border-box;
  }
`;

const Remark = styled.div`
  font-size: 10px;
  color: red;
`;

const DateAndTimeBooking = ({
  setActiveCourse,
  activeCourse,
  courses,
  program,
  errorFields,
}) => {
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const [availableTimeslots, setAvailableTimeslots] = useState([]);

  useEffect(() => {
    if (activeCourse?.option1value) {
      setSelectedDate(
        activeCourse?.option1value
          ? new Date(activeCourse.option1value).toISOString().slice(0, 10)
          : null
      );
    }
  }, [activeCourse]);

  // Simulate 21 dates starting from today
  const generateDates = () => {
    // const dates = [];
    // const today = new Date();
    // for (let i = 0; i < 21; i++) {
    //   const futureDate = new Date(
    //     today.getFullYear(),
    //     today.getMonth(),
    //     today.getDate() + i
    //   );
    //   dates.push(futureDate.toISOString().slice(0, 10)); // YYYY-MM-DD format
    // }

    const dates = courses?.map((course) => {
      const date = new Date(course?.option1value);
      return date.toISOString().slice(0, 10); // YYYY-MM-DD format
    });

    const uniqueDates = Array.from(new Set(dates));
    return uniqueDates;
  };

  const dates = generateDates();

  // console.log(errorFields);

  // Simulate timeslot availability
  useEffect(() => {
    const simulateTimeslotAvailability = () => {
      // Filter the courses for the selected date
      const coursesForSelectedDate = courses.filter(
        (course) =>
          new Date(course?.option1value).toISOString().slice(0, 10) ===
          selectedDate
      );

      // Map the courses to their times and filter out the unavailable ones
      const availableTimes = coursesForSelectedDate.filter(
        (course) => course.open === 1
      ); // replace 'available' with the actual value indicating availability

      return availableTimes;
    };

    if (activeCourse?.option1value || selectedDate) {
      const availableTimes = simulateTimeslotAvailability();
      setAvailableTimeslots(availableTimes);

      console.log(availableTimes);

      // Set the first available timeslot as selected and set the active course
      const firstAvailableTimeslot = availableTimes.find(
        (time) => time.availability > 0
      );

      if (firstAvailableTimeslot) {
        setActiveCourse(firstAvailableTimeslot);
      } else if (activeCourse !== null) {
        setActiveCourse(activeCourse);
        // console.log("No available timeslots");
      }
    }
  }, [selectedDate]);

  return (
    <Container>
      {/* <h2>Book Your Workshop</h2> */}
      <Dropdown
        value={selectedDate}
        onChange={(e) => setSelectedDate(e.target.value)}
      >
        <option value="">Select a Date</option>
        {dates.map((date, index) => (
          <option key={index} value={date}>
            {date}
          </option>
        ))}
      </Dropdown>

      {/* {errorFields.option2Value && "*"} */}
      {selectedDate && (
        <Grid>
          {availableTimeslots.map((time, index) => {
            const isAvailable = time.availability > 0;
            return (
              <TimeslotButton
                key={index}
                available={isAvailable}
                selected={activeCourse?.option2value === time.option2value}
                onClick={() => isAvailable && setActiveCourse(time)}
                className={!isAvailable ? "full" : ""}
              >
                {time.option2value}
                {isAvailable ? (
                  time.availability < 5 && (
                    <Remark>{time.availability} spots left</Remark>
                  )
                ) : (
                  <Remark>Full</Remark>
                )}
              </TimeslotButton>
            );
          })}
        </Grid>
      )}

      {/* {selectedTime && (
        <p>
          Selected: {selectedDate} at {selectedTime}
        </p>
      )} */}
    </Container>
  );
};

export default DateAndTimeBooking;
