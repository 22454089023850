import axios from 'axios';

let protocol, domain, port;

if (process.env.REACT_APP_NODE_ENV === 'production') {
  protocol = process.env.REACT_APP_PROTOCOL;
  domain = process.env.REACT_APP_DOMAIN;
  port = '';
} else {
  protocol = 'http';
  domain = `booking.dynevents.local`;
  port = ':8069';
}
const host = window.location.hostname;
const subdomain = host.split('.')[0];
const shopName = subdomain;

const baseUrl = `${protocol}://${shopName}.${domain}${port}`;

const api = axios.create({
  baseURL: baseUrl,
  withCredentials: true,
});

export default api;