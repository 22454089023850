import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { SuccessSubmission, FailSubmission } from "./ModalMessage";

export const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999;
`;

export const ModalContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 500px;
  width: 90%;
`;

export const MessageModal = ({ isOpen, autoClose, template, language }) => {
  const [modalOpen, setModalOpen] = useState(isOpen);

  // Effect to sync internal state with the isOpen prop
  useEffect(() => {
    setModalOpen(isOpen);
  }, [isOpen]);

  useEffect(() => {
    let timer;
    if (autoClose && modalOpen) {
      timer = setTimeout(() => {
        setModalOpen(false);
      }, 5000);
    }

    // Cleanup the timer when the component unmounts or modal state changes
    return () => clearTimeout(timer);
  }, [autoClose, modalOpen]);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  if (!modalOpen) return null;

  return (
    <ModalBackground onClick={toggleModal}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        {template === "success" && <SuccessSubmission language={language} />}
        {template === "fail" && <FailSubmission language={language} />}
        {!autoClose && <button onClick={toggleModal}>Close</button>}
      </ModalContent>
    </ModalBackground>
  );
};

export default MessageModal;
