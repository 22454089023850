import React, { useEffect, useState, useRef, useContext, useCallback } from "react";
import styled from "styled-components";
import QRCode from 'qrcode.react';
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import AbsentIcon from "./AbsentIcon"; // Adjust the path as necessary
import AttendedIcon from "./AttendedIcon"; // Adjust the path as necessary
import ExcusedIcon from "./ExcusedIcon"; // Adjust the path as necessary

const BackIcon = styled.img`
  width: 24px;
  position: relative;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
`;
const Glyph = styled.div`
  width: 40px;
  height: 40px;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
const ScreenTitle = styled.i`
  align-self: stretch;
  position: relative;
  line-height: 18px;
  font-size:20px;
  font-weight:700;
`;

const ScreenSubtitle = styled.div`
  width: 247px;
  position: relative;
  font-size: var(--font-size-sm);
  line-height: 14px;
  color: #ffffff;
  display: block;
   font-size:20px;
  font-weight:700;
`;
const TitleWrap = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 5px;
  gap: var(--gap-5xs);
`;
const Glyph1 = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
const Topbar = styled.div`
  align-self: stretch;
  background-color: var(--color-steelblue-100);
  height:70px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 5px;
  box-sizing: border-box;
  gap:5px;
  // border:1px solid red;
`;

// const Fixed = styled.div`
//   position: relative;
//   top: 44px;
//   left: calc(50% - 187.5px);
//   backdrop-filter: var(--blur-glass);
//   background-color: rgba(51, 75, 123, 0.7);
//   width: 375px;
//   display: flex;
//   flex-direction: column;
//   align-items: flex-start;
//   justify-content: flex-start;
// `;

const CodeIcon = styled.img`
  width: 240px;
  position: relative;
  height: 240px;
  object-fit: cover;
`;
const CodeWrapper = styled.div`
  box-shadow: 0px 4px 32px rgba(255, 255, 255, 0.25);
  border-radius: var(--br-base);
  background-color: var(--text-main);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xs);
`;
const Description = styled.div`
  align-self: stretch;
  position: relative;
  white-space: pre-wrap;
`;
const Qr = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-base);
  gap: var(--gap-base);
`;
const TearLineChild = styled.img`
  width: 20px;
  position: relative;
  height: 40px;
`;
const TearLineItem = styled.div`
  width: 12px;
  position: relative;
  border-radius: 50%;
  background-color: var(--color-gray-100);
  height: 12px;
`;
const EllipseIcon = styled.img`
  width: 20px;
  position: relative;
  height: 40px;
  object-fit: contain;
`;
const TearLine = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
const A069IndividualPrivate = styled.i`
  flex: 1;
  position: relative;
`;

const Infoheader = styled.i`
  flex: 1;
  position: relative;
  font-size: 20px;
  font-weight: 700;
`;

const Title = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;
const Venue = styled.div`
  width: 100px;
  position: relative;
  line-height: 18px;
  display: inline-block;
  flex-shrink: 0;
`;
const PrivateRink = styled.i`
  align-self: stretch;
  position: relative;
`;
const Wrap = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  color: var(--text-main);
`;
const Item = styled.div`
  width: 343px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
`;
const Monday = styled.i`
  flex: 1;
  position: relative;
  line-height: 18px;
  color: var(--text-main);
`;
const Pm = styled.div`
  position: relative;
  line-height: 18px;
  color: var(--text-main);
`;
const BenMacaskill = styled.i`
  align-self: stretch;
  flex: 1;
  position: relative;
  line-height: 18px;
  color: var(--text-main);
`;
const Details = styled.div`
  align-self: stretch;
  height: 114px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
  color: var(--color-lightsteelblue);
`;
const Info = styled.div`
  align-self: stretch;
  height: 163px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-base);
  box-sizing: border-box;
  gap: var(--gap-base);
  text-align: left;
  font-family: var(--font-abeezee);
`;
const Ticket = styled.div`
  position: relative;
  // top: 108px;
  margin-bottom: 44px;
  left: calc(50% - 188px);
  border-radius: 0px 0px var(--br-base) var(--br-base);
  background-color: var(--color-steelblue-100);
  width: 376px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: var(--font-size-sm);
  font-family: var(--font-ibm-plex-sans);
`;
const QrCodeRoot = styled.div`
  // border: solid green 3px;

  width: 100%;
  position: relative;
  background-color: #c4edff;
  // height: 802px;
  overflow: hidden;
  text-align: center;
  font-size: var(--font-size-lg);
  color: var(--text-main);
  font-family: var(--font-abeezee);
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 350px;
  margin: 0 auto;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #ccc;
  width:100%;
    color:black;
`;

export const Header = styled.div`
  font-weight: bold;
  text-align: left;
  flex: 1;
`;

export const Cell = styled.div`
  text-align: left;
  flex: 1;

`;

export const ThickLine = styled.div`
  height: 1.5px;
  background-color: black;
  margin-bottom: 10px;
`;

export const NameTitle = styled.div`
  font-weight: 700;
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 20px;
  border-left: 10px solid rgb(0, 0, 139);
  width: 100%;
  color: black;
  padding-left: 20px;
`;

export const ClassInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #ffffff;
  width:100%;
  padding:10px 10px;
  // border-radius: 0 0 20px 20px;
  border-bottom:15px solid lightgrey;
`;

export const Subheader = styled.div`
  font-weight: 700;
  font-size: 20px;
  // text-align: left; // Center text
  margin-top: 10px; // Adjust spacing after venue
  margin-bottom:20px;
  // display:flex;
  // align-items:center;
  // justify-content:flex-start;
  border-left:10px solid rgb(0,0,139);
  width:100%;
  color:black;
  padding-left:20px;
  
`;

export const ClassTitle = styled.div`
  width: 95%;
  height: auto;
  font: 700 20px Poppins, sans-serif;
  text-align: center;
  margin-bottom: 10px;
  color:black;
  // border-bottom: 2px solid green;
`;

const  QrCodeTicket = ({orderInfo, language}) => {

const orderDetails = orderInfo.order_details 
const participantDetails = orderInfo.participant_info
const contact = orderInfo.contact;
const email = orderInfo.email;
const orderToken = orderInfo.token;



//  const response = await axios.get(`http://empire.booking.dynevents.local:8089/classBooking/api/class/checkin/${id}`, {   withCredentials: true,});


// setCheckInSchedule(response.data);

// 			} catch (err) {
// 				console.log(err);
// 			}
// 		};

// 		fetchData();

// 	}, []);


// const attendedClassesCount = checkinSchedule?.filter(classItem => classItem.status === 1).length;



  return (
    <QrCodeRoot>

{language === "en" ? (  <Ticket>
        <Topbar>
          <TitleWrap>
            <ScreenTitle>Your ticket</ScreenTitle>
            <ScreenSubtitle>{orderInfo?.shopname}</ScreenSubtitle>
          </TitleWrap>
        </Topbar>
        <Qr>
          <CodeWrapper>
          <QRCode value={`https://booking.dynevents.com/admin/checkin/?token=${orderToken}`} /> {/* Replace with your data */}

          </CodeWrapper>
          <Description>Scan this code for attendance at the venue</Description>
        </Qr>
        <TearLine>
          <TearLineChild alt="" src="/ellipse-3.svg" />
          <TearLineItem />
          <TearLineItem />
          <TearLineItem />
          <TearLineItem />
          <TearLineItem />
          <TearLineItem />
          <TearLineItem />
          <TearLineItem />
          <TearLineItem />
          <TearLineItem />
          <TearLineItem />
          <TearLineItem />
          <TearLineItem />
          <TearLineItem />
          <TearLineItem />
          <TearLineItem />
          <EllipseIcon alt="" src="/ellipse-4.svg" />
        </TearLine>
        <Info>
          <Title>
            <A069IndividualPrivate>
             {orderDetails?.programName}
            </A069IndividualPrivate>
              <A069IndividualPrivate>
              {orderDetails?.option1value}
            </A069IndividualPrivate>
               <A069IndividualPrivate>
              {orderDetails?.option2value}
            </A069IndividualPrivate>
              <A069IndividualPrivate>
           {orderDetails?.fee === "0" ? 'Free' : `HK$ ${orderDetails?.fee}`}
            </A069IndividualPrivate>
          </Title>
          <Details>
            <Item>
              <Venue>Venue</Venue>
              <Wrap>
                <PrivateRink>{orderDetails?.venue}</PrivateRink>
              </Wrap>
            </Item>
          </Details>
        </Info>
 <TearLine>
          <TearLineChild alt="" src="/ellipse-3.svg" />
          <TearLineItem />
          <TearLineItem />
          <TearLineItem />
          <TearLineItem />
          <TearLineItem />
          <TearLineItem />
          <TearLineItem />
          <TearLineItem />
          <TearLineItem />
          <TearLineItem />
          <TearLineItem />
          <TearLineItem />
          <TearLineItem />
          <TearLineItem />
          <TearLineItem />
          <TearLineItem />
          <EllipseIcon alt="" src="/ellipse-4.svg" />
        </TearLine>
 <Info>
          <Title>
            <A069IndividualPrivate>
        Participant Info
            </A069IndividualPrivate>
          </Title>
          <Details>
            <Item>
              <Venue>Name</Venue>
              <Wrap>
                <PrivateRink>      {participantDetails?.firstname}   </PrivateRink>
              </Wrap>
            </Item>
            <Item>
              <Venue>Date of birth</Venue>
              <Wrap>
                <PrivateRink> {participantDetails?.dob}</PrivateRink>
              </Wrap>
            </Item>
              <Item>
              <Venue>Gender</Venue>
              <Wrap>
                <PrivateRink> {participantDetails?.gender}</PrivateRink>
              </Wrap>
            </Item>
          </Details>
        </Info>

        <Info>
          <Title>
            <A069IndividualPrivate>
       Contact Info
            </A069IndividualPrivate>
          </Title>
          <Details>
            <Item>
              <Venue>Mobile</Venue>
              <Wrap>
                <PrivateRink>      {contact}</PrivateRink>
              </Wrap>
            </Item>
            <Item>
              <Venue>Email</Venue>
              <Wrap>
                <PrivateRink>   {email}</PrivateRink>
              </Wrap>
            </Item>
          </Details>
        </Info>


      </Ticket>) : (  <Ticket>
        <Topbar>
          <TitleWrap>
            <ScreenTitle>預約憑證</ScreenTitle>
            <ScreenSubtitle>{orderInfo?.shopname}</ScreenSubtitle>
          </TitleWrap>
        </Topbar>
        <Qr>
          <CodeWrapper>
          <QRCode value={`https://booking.dynevents.com/admin/checkin/?token=${orderToken}`} /> {/* Replace with your data */}

          </CodeWrapper>
          <Description>請用以下二維碼到場簽到</Description>
        </Qr>
        <TearLine>
          <TearLineChild alt="" src="/ellipse-3.svg" />
          <TearLineItem />
          <TearLineItem />
          <TearLineItem />
          <TearLineItem />
          <TearLineItem />
          <TearLineItem />
          <TearLineItem />
          <TearLineItem />
          <TearLineItem />
          <TearLineItem />
          <TearLineItem />
          <TearLineItem />
          <TearLineItem />
          <TearLineItem />
          <TearLineItem />
          <TearLineItem />
          <EllipseIcon alt="" src="/ellipse-4.svg" />
        </TearLine>
        <Info>
          <Title>
            <A069IndividualPrivate>
             {orderDetails?.programNameChi}
            </A069IndividualPrivate>
              <A069IndividualPrivate>
              {orderDetails?.option1value}
            </A069IndividualPrivate>
               <A069IndividualPrivate>
              {orderDetails?.option2value}
            </A069IndividualPrivate>
              <A069IndividualPrivate>
           {orderDetails?.fee === "0" ? '免費' : `HK$ ${orderDetails?.fee}`}
            </A069IndividualPrivate>
          </Title>
          <Details>
            <Item>
              <Venue>地點</Venue>
              <Wrap>
                <PrivateRink>{orderDetails?.venue_chi}</PrivateRink>
              </Wrap>
            </Item>
          </Details>
        </Info>
 <TearLine>
          <TearLineChild alt="" src="/ellipse-3.svg" />
          <TearLineItem />
          <TearLineItem />
          <TearLineItem />
          <TearLineItem />
          <TearLineItem />
          <TearLineItem />
          <TearLineItem />
          <TearLineItem />
          <TearLineItem />
          <TearLineItem />
          <TearLineItem />
          <TearLineItem />
          <TearLineItem />
          <TearLineItem />
          <TearLineItem />
          <TearLineItem />
          <EllipseIcon alt="" src="/ellipse-4.svg" />
        </TearLine>
 <Info>
          <Title>
            <A069IndividualPrivate>
        參加者資料
            </A069IndividualPrivate>
          </Title>
          <Details>
            <Item>
              <Venue>姓名</Venue>
              <Wrap>
                <PrivateRink>      {participantDetails?.firstname}   </PrivateRink>
              </Wrap>
            </Item>
            <Item>
              <Venue>出生日期</Venue>
              <Wrap>
                <PrivateRink> {participantDetails?.dob}</PrivateRink>
              </Wrap>
            </Item>
              <Item>
              <Venue>性別</Venue>
              <Wrap>
                <PrivateRink> {participantDetails?.gender}</PrivateRink>
              </Wrap>
            </Item>
          </Details>
        </Info>

        <Info>
          <Title>
            <A069IndividualPrivate>
       聯絡資料
            </A069IndividualPrivate>
          </Title>
          <Details>
            <Item>
              <Venue>電話號碼</Venue>
              <Wrap>
                <PrivateRink>      {contact}</PrivateRink>
              </Wrap>
            </Item>
            <Item>
              <Venue>電郵</Venue>
              <Wrap>
                <PrivateRink>   {email}</PrivateRink>
              </Wrap>
            </Item>
          </Details>
        </Info>


      </Ticket>)}



         
    </QrCodeRoot>
  );
};

export default QrCodeTicket;
