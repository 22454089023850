//ClassDetails.js
import React, { useEffect, useState, useContext} from "react";
import styled from "styled-components";
import axios from "axios";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import ButtonMobile from "../../components/ButtonMobile/ButtonMobile"; //Join the class button
import ButtonBack from "../../components/ButtonMobile/ButtonBack"; //Circle Back Button
import LessonSelector from "../../components/SelectClassButton/SelectClassButton"; //Select Class Buttons
import {LocationPin} from "../../assets/IconSVG.js";
import DateAndTimeBooking from "../../components/Form/DateAndTimeBooking"; //Select 
import Cookies from 'js-cookie';
import api from '../../Api'; // adjust the path based on where api.js is located
import { HistoryContext } from '../../context/historyContext';



export const ClassCard = ({ classInfo }) => {
  const navigate = useNavigate()
const [price, setPrice] = useState(0);
const [order, setOrder] = useState({
  course_id:"",
  		price: "",
		name: "",
		option1Value: "",
    option2Value: "",
		fee: "",
	});


  const [date, setDate] = useState('');
  const [time, setTime] = useState('');


  const handleDateTimeChange = (newDate, newTime) => {
    setDate(newDate);
    setTime(newTime);
  };

const [errorFields, setErrorFields] = useState({});



  const validateOrder = (order) => {
  let errors = {};
  console.log(order)
  for (let key in order) {
    if (order[key] === "") {
      errors[key] = true;
    }
  }
  setErrorFields(errors);
 return Object.keys(errors).length === 0; // Valid form if no errors/ return true or false
};







  const handleSubmit = () => {

   const activeCourseFromCookie = Cookies.get('activeCourse');

   let activeCourse = {}
if (activeCourseFromCookie) {
  activeCourse = JSON.parse(activeCourseFromCookie).course;
  // Now you can use activeCourse in your code


 const isValid = validateOrder(activeCourse);

    if (isValid) {
     	navigate(`/ch/classconfirmpayment`);
    }
  }
  }

const [activeCourse, setActiveCourse] = useState(null);
const [activeProgram, setActiveProgram] = useState(null)
const { history, setHistory } = useContext(HistoryContext);



useEffect(() => {
  localStorage.setItem('history', JSON.stringify(history));
  console.log(history)
}, [history]);


useEffect(() => {

setHistory(location.pathname + location.search);

  if (classInfo.courses && classInfo.courses.length > 0) {
    let activeCourseFromCookie = Cookies.get('activeCourse');
const { courses, ...rest } = classInfo;



    if (activeCourseFromCookie) {
      activeCourseFromCookie = JSON.parse(activeCourseFromCookie);

      if (activeCourseFromCookie.program.id === classInfo.id) { // Check if the program IDs match
        setActiveCourse(activeCourseFromCookie.course);
        setActiveProgram(activeCourseFromCookie.program);
      } else {
        Cookies.remove('activeCourse'); // Remove the cookie if the program IDs don't match
       const firstAvailableCourse = classInfo.courses.find(course => course.availability === null || course.availability > 0);
        if (firstAvailableCourse) {
          setActiveCourse(firstAvailableCourse);
          setActiveProgram(rest);
          Cookies.set('activeCourse', JSON.stringify({ course: firstAvailableCourse, program: rest  }));
        }
      }
    } else {
      const firstAvailableCourse = classInfo.courses.find(course => course.availability === null || course.availability > 0);
      if (firstAvailableCourse) {
        setActiveCourse(firstAvailableCourse);
        setActiveProgram(rest);
        Cookies.set('activeCourse', JSON.stringify({ course: firstAvailableCourse, program: rest  }));
      }
    }
  }
  // Cleanup function
  // return () => {
  //   Cookies.remove('activeCourse');
  // };




  


}, [classInfo]);

useEffect(() => {
  if (activeCourse) {
     const { courses, ...rest } = classInfo;
    Cookies.set('activeCourse', JSON.stringify({ course: activeCourse, program: rest }));
  }else{

Cookies.remove('activeCourse');
  }
}, [activeCourse])


const location = useLocation();
const queryParams = new URLSearchParams(location.search);
const single = queryParams.get('single');


  return (
    <>
      <CardContent>
    

          {!single && (     <ButtonWrapper><ButtonBack onClick={() => navigate('/')}>
            </ButtonBack>
        </ButtonWrapper>)}
         
        <ClassImage
          loading="lazy"
          src={classInfo.images}
          alt={classInfo.
option1value}
        />
              <CardContentWrapper>
                <ClassTitle>
          {classInfo.name_chi}
        </ClassTitle>
        <Label>{classInfo.category}</Label>
       <ClassVenue> <LocationPin/>{classInfo.venue_chi}</ClassVenue> 
        <ClassDetailsStyled>
            <DetailItem>
                    <DetailLabel>年齡</DetailLabel>
              <DetailValue>{classInfo.age}</DetailValue>
            </DetailItem>
              {/* <DetailItem>
              <DetailLabel>Class Dates</DetailLabel>
              <DetailValue>{classInfo.age}</DetailValue>
            </DetailItem> */}
          {/* {classInfo.details.map((detail, index) => (
            <DetailItem key={index}>
              <DetailLabel>{detail.label}</DetailLabel>
              <DetailValue>{detail.value}</DetailValue>
            </DetailItem>
          ))} */}
        </ClassDetailsStyled>
   

        {/* ***********************************
              SELECT BELOW AREA - OPTIONAL
        *********************************** */}
  {classInfo?.useTimeslotMode? (

<>
 <Subheader>選擇時段</Subheader>

        <DateAndTimeBooking courses={classInfo?.courses} program={classInfo} setActiveCourse={setActiveCourse} activeCourse={activeCourse} errorFields={errorFields}/>
</>
  ) : ( 

<>
<Subheader>Course options</Subheader>

        <LessonSelector courses={classInfo?.courses} program={classInfo} setActiveCourse={setActiveCourse} activeCourse={activeCourse} errorFields={errorFields}/>
    </>
)

}

<Subheader>課堂內容</Subheader>

        <ClassDescription>
          <span>{classInfo?.description_chi}</span>
        </ClassDescription>

        <JoinClassFooterWrapper>

           {classInfo?.useTimeslotMode? ( <OptionListPrice>{activeCourse?.option1value} {activeCourse?.option2value}</OptionListPrice>) : ( <OptionListPrice>HK$ {activeCourse?.fee}</OptionListPrice>)}
               
          	<ButtonMobile onClick={handleSubmit}>預訂</ButtonMobile>
        </JoinClassFooterWrapper>
             </CardContentWrapper>
      </CardContent>
    </>
  );
};

export const ClassDetails = () => {

const [data, setData] = useState([]);
const location = useLocation();
	const {program_code} = useParams();

const host = window.location.hostname;
const subdomain = host.split('.')[0];
const shopName = subdomain;
  
  
  useEffect(() => {
		const fetchData = async () => {
			try {
				const res = await api.get(
					`/classBooking/api/class/${program_code}`
				);

				// let uniqueArray = [...new Set(res.data.map((item) => item.category))]; // Remove duplicates

				// let newtabValues = [
				// 	{ name: "tab_1", label: "All Events" },
				// 	...uniqueArray.map((item, index) => ({
				// 		name: `tab_${index + 2}`, // Start from tab_2
				// 		label: item,
				// 	})),
				// ];
				setData(res.data);
			} catch (err) {
				console.log(err);
			}
		};

		fetchData();
		// setResultChange("All");

		// const intervalId = setInterval(fetchData, 1000);

		// Clean up the interval when the component unmounts or when dependencies change
		// return () => {
		// 	clearInterval(intervalId);
		// };
	}, []);


  return (
    <>
        <ClassCard classInfo={data} />
    </>
  );
};
export default ClassDetails;

//STYLED-COMPONENTS:
export const CardContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  padding-bottom: 100px;
  color:black;
`;

export const ButtonWrapper = styled.div`
  position: absolute;
  top: 22px;
  left: 22px;
  z-index: 10;
`;

export const ClassImage = styled.img`
  object-fit: auto;
  object-position: center;
  width: 100%;
`;

export const CardContentWrapper = styled.div`
width:100%;
display:flex;
flex-direction:column;
align-items:flex-start;
padding: 10px 20px;


`

export const ClassTitle = styled.div`
  font: 700 28px Poppins, sans-serif;
  text-align: left; // Center text
  margin-top: 10px;
  // margin-bottom: 10px; // Adjust spacing after title
`;


export const Label = styled.div`
  font: 15px Lora, sans-serif;
  text-align: center; // Center text
  // margin-bottom: 20px; // Adjust spacing after venue
  // border:1px solid red;
  background-color:lightgrey;
  color:black;
  border-radius:2px;
  padding:2px 5px;

`;

export const DetailsContainer = styled.div`
  font: 15px Lora, sans-serif;
  text-align: left; // Center text
  // margin-bottom: 20px; // Adjust spacing after venue
  // border:1px solid red;
  background-color:rgba(227, 188, 154, 10);
  color:black;
  border-radius:2px;
  padding:2px 5px;

`;

export const ClassVenue = styled.div`
  font: italic 700 20px Lora, sans-serif;
  // text-align: left; // Center text
  margin-top: 10px; // Adjust spacing after venue
  display:flex;
  align-items:center;
  justify-content:flex-start;
  // border:1px solid red;
  width:100%;
  color:grey;
  
svg{
  width:20px;
  margin-right:10px;
  fill:grey;
}

`;

export const Subheader = styled.div`
  font-weight: 700;
  font-size: 20px;
  // text-align: left; // Center text
  margin-top: 10px; // Adjust spacing after venue
  margin-bottom:20px;
  // display:flex;
  // align-items:center;
  // justify-content:flex-start;
  border-left:10px solid rgb(0,0,139);
  width:100%;
  color:black;
  padding-left:20px;
  

`;




export const ClassDetailsStyled = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr; // Defines two columns: 1st column is 1/4th, 2nd column is 3/4ths of the total width
  gap: 10px; // Gap between grid items
  width: 85%; //Makes the grid 80% of the width of the parent container
  padding: 10px 0px;
`;

export const DetailItem = styled.div`
  display: contents; // Makes the container behave like a direct child of the grid container
`;

export const DetailLabel = styled.span`
  color: var(--Text-Muted, gray);
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 600;
`;

export const DetailValue = styled.span`
  font-family: Lora, sans-serif;
  font-weight: 500;
  font-size: 14px;
`;

export const TransportationNote = styled.p`
  font: italic 700 14px Lora, sans-serif;
  align-self: center;
  margin-top: 10px;
  color: #ff4f4f;
  text-align: center;
`;

export const ClassDescription = styled.div`
  justify-content: center;
  background-color: #ffffff;
  width: 100%;
  color: black;
  font-size:18px;
  padding: 20px 10px 20px 10px;

`;

const JoinClassFooterWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 12px 12px 0 0;
  background-color: var(--color-white);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding: 11px;
  gap: 20px;
  z-index: 1000;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1); // Very thin drop shadow
  @media (min-width: 500px) {
    width: 500px; /* Apply max-width constraint */
    left: calc((100vw - 500px) / 2);
    right: calc((100vw - 500px) / 2);
  }
`;

export const OptionListPrice = styled.div`
	// width: 100%;
	// margin: 10px auto;
  text-align:left;
  font-size:20px;
  font-weight:800;
`;

